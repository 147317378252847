.LeftList,
.RightList{
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
    margin: 10px;
    padding: 10px;
}
.minTitle{
    font-size: 0.8rem;
    color: #003380;
    margin-bottom: 1rem;
}
.subTitle{
    font-size: 1.125rem;
    color: #003380;
    margin-bottom: 1rem;
}
