.subTitle{
  font-size: 1.38rem;
  color: #003380;
  margin-bottom: 1rem;
}
p{
  font-size: 1.1rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: #737373;
}
.button{
  background: #fff !important;
  border: 1px solid #5ccb5f !important;
  color: #5ccb5f !important;
  border-radius: 12px;
  font-size: 1.2rem;
  &:hover {
    background: #5ccb5f !important;
    color: #fff !important;
  }
}
.video{
  border-radius: 12px !important;
  overflow: hidden;
}
  