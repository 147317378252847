.container{
    display: flex;
    padding: 0px !important;
}
.filters {
    display: flex;
    padding: 15px;
    font-weight: 500;
    font-size: 1rem;
    color: black;
    border-bottom: 1px solid #B1B1B1;
}
.icon {
    color: white;
}
.tabs {
    margin: 0 10px;
    border-bottom: 1px solid #B1B1B1;

    .tabSelector {
        padding-bottom: 10px;
        border-bottom: 5px solid transparent;

    }

    .selected {
        border-bottom-color: #003380;
        transition: border-bottom 0.3s ease;
    }
}
@media (max-width: 1024px) {
    .container{
        flex-direction: column;
    }
}
@media (max-width: 768px) {
    .container{
        flex-direction: column;
    }
}
