.text {
  color: #393939;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 2rem;
  text-align: left;
}
.img_header {
  height: auto;
  width: 350px;
  margin: 0 auto;
  display: block;
  border-radius: 24px;
}
.banner {
  background-image: url("../../../../../../../../assets/images/banners/banner_05.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
