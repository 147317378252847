.card {
  display: inline-block;
  font-family: 'Montserrat';
  color: #b1b1b1;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.4rem;
  width: 252px;
  margin: 1.2rem;
  background: #FFFFFF;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.imgContainer {
  height: 150px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 1.0rem;
  border-bottom: 1px solid #D9D9D9;
  .img {
    width: auto;
    height: 100%;
  }
}
%ellipsis {
  display: inline-block;
  text-overflow: ellipsis !important;
  width: 100%;
  line-height: 18px;
  font-size: 1.0rem;
}
.title {
  height: 54px;
  color: #003380;
  margin-bottom: 0.5rem;
}
.description {
  margin-bottom: 2rem;
  font-size: 14px;
}
.buttonCard {
  display: flex;
  height: 37px;
  border-radius: 12px;
  border: 1px solid #5ccb5f !important;
  color: #5ccb5f !important;
  font-size: 1.125rem;
  font-weight: 500;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #5ccb5f !important;
    color: #fff !important;
  }
}
// @media (max-width: 768px) {
//   .card{
//       width: 220px;
//   }
// }
