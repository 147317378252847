.subTitle{
    font-size: 1.125rem;
    color: #003380;
    margin-bottom: 1rem;
}
p{
    display: inline-block;
    text-overflow: ellipsis !important;
    font-size: 1.1rem;
    color: #737373;
    text-align: justify;
}
a{
    font-weight: bold;
    color: #737373;
}
