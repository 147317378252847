p {
  margin: 0;
}

li {
  margin-bottom: 0.5rem;
  .link_custom:hover{
    color: #9c9c9c;
  }
}

.title {
  font-size: 1.4rem;
  font-weight: bold;
}

.icon {
  font-size: 1.5rem;
  color: #fff;
}

a .icon:hover {
  color: #9c9c9c;
}

.footer {
  background-color: #003380;
  color: white;
  padding: 2rem 0 0 0;
  margin-top: auto;
  font-family: "Montserrat", sans-serif;
}

.list_link {
  margin-bottom: 0.75rem;
  li {
    font-size: 1rem;
    font-weight: 200;
    line-height: normal;
  }
}

.logo_airline {
  width: auto;
  height: 120px;
  margin-bottom: 2rem;
  &_3 {
    width: auto;
    height: 32px;
    margin-bottom: 1rem;
  }
}

.flex-text {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 1.1rem;
  color: white;
}

.info_contacts {
  .icon {
    margin-right: 0.75rem;
    font-size: 1.2rem;
  }
  p {
    margin: 0.3rem 0;
  }
  p,
  a {
    @extend.flex-text;
  }
  a:hover {
    color: #9c9c9c;
  }
}

.copyRight {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #002d70;
  min-height: 60px;
  padding: 0.75rem;
  margin-top: 2rem;
  span {
    margin-right: 0.5rem;
    font-size: 0.85rem;
  }
}

.social {
  &_group {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    margin-bottom: 1.5rem;
    .icon {
      font-size: 36px;
      &:hover {
        filter: grayscale(10%) brightness(80%) contrast(100%);
      }
    }
  }
  &_logo {
    width: auto;
    height: 32px;
    &:hover {
      filter: grayscale(10%) brightness(80%) contrast(100%);
    }
  }
}

@media screen and (max-width: 600px) {
  .logo_airline {
    height: 90px;
    &_2 {
      height: 36px;
    }
    &_3 {
      height: 28px;
    }
  }
  .copyRight span {
    font-size: 0.75rem;
  }
}
