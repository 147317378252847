.cardContainer {
    position: relative;
    min-width: 200px;
    min-height: 100px;
    border-radius: 12px;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
    FontAwesomeIcon {
        position: absolute;
        top: 15px;
        left: 15px;
        color: white;
    }
}

.blueShape {
    width: 126px;
    height: 77px;
    background: #005ce6;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 100px;
}