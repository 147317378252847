.container{
    display: flex;
    padding: 0px !important;
}
@media (max-width: 1024px) {
    .container{
        flex-direction: column;
    }
}
@media (max-width: 768px) {
    .container{
        flex-direction: column;
    }
}