.text {
  color: #003380;
  font-size: 1.75rem;
}

.bar {
  background: #005ce6;
  width: 46px;
  height: 4px;
  border-radius: 10px;
}
