.text {
  color: #393939;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
}

.img_support {
  border-radius: 12px;
  max-height: 400px;
  width: 100%;
  display: flex;
  margin: auto;
}

.banner {
  background-image: url("../../../../../assets/images/banners/banner_05.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
