.sidebar {
    display: block;
    background-color: #003380;
    min-width: auto;
    max-width: auto;
    &_title {
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
        position: relative;
        margin-bottom: 1.25rem;
        &::after {
            content: "";
            position: absolute;
            display: block;
            width: 40px;
            height: 4px;
            border-radius: 2px;
            background-color: white;
            bottom: -5px;
            left: 0;
        }
    }
    &_links {
        display: flex;
        flex-direction: column;
        &_item {
            text-decoration: none;
            color: white;
            font-size: 0.9em;
            font-weight: 500;
            display: block;
            padding: 0.25rem 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            &:hover {
                font-weight: 700;
                transition: all 0.5ms;
                color: white;
            }
        }
    }
}