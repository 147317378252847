.table {
  border-collapse: collapse;
  width: 100%;
  background-color: white;
  border-spacing: 0;
  margin-bottom: 1rem;
  th,
  td {
    padding: 10px;
    text-align: left;
    border: 2px solid #e6e6e6;
  }
  th {
    background-color: #e6e6e6;
    color: black;
  }
}
.btn {
  &_link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    min-width: 90px;
    max-width: fit-content;
    padding: 0.5rem 0.75rem;
    color: orange;
    border: 1px solid orange;
    background-color: #ffffff;
    border-radius: 12px;
    text-decoration: none;
    gap: 0.5rem;
    &:hover {
      color: #ffffff;
      background-color: orange;
    }
  }
}
