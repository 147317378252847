.cardContent {
  padding: 1rem;
  color: #b1b1b1;
  font-weight: 600;
}

.title {
  font-size: 1.375rem;
  color: #003380;
}

.description {
  font-size: 1.125rem;
}

.platforms {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.button {
  width: 50%;
  border-color: #5ccb5f !important;
  color: #5ccb5f !important;
  &:hover {
    background: #5ccb5f !important;
    color: #fff !important;
  }
}
